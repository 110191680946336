* {
    box-sizing: border-box;
  }
  
  .contact-page {
    background-image: url(../../media/tractor.jpeg);
    background-size:cover;
    background-position: center center;
    opacity: 0.85;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
  } 
  
  .card {
    background-color: #fff;
    border-radius: 0.625rem;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.3);
    padding: 1.25rem;
    width: 70%;
  }
  
  .card-header {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .card-header h2 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
  }
  
  .card-body {
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
  align-items: stretch; /* align child divs to stretch vertically */
  }
  
  .form-container {
    width: 50%;
    max-width: 31.25rem;
    margin: 0 auto;
    padding: 0 1.25rem;
  }
  
  .form-container form {
    display: flex;
    flex-direction: column;
    
  }
  
  .form-container input,
  .form-container textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 0.0625rem solid #ccc;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-family:  'Georgia', sans-serif;
    letter-spacing: 0.0625rem;
  }
  
  .form-container input:focus,
  .form-container textarea:focus {
    outline: none;
    box-shadow: 0 0 0.3rem #357960;
  }
  
  .form-container button {
    padding: 0.5rem 1.5rem;
    background-color: rgba(15,93,15);
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    align-self: start;
  }
  
  .form-container button:hover {
    background-color: #2b614a;
  }
  
  .contact-info {
    width: 40%;
    flex-wrap: wrap;
    margin: 0 auto;
    padding-left: 1.25rem;
    text-align: center;
  }
  
  .contact-info h3 {
    margin: 0;
    }
  
  .contact-info p {
    margin: 0;
    font-size: 1rem;
    }
  
  .contact-info .icon{
     margin-top: 1rem; 
    font-size: 1.5rem;
    color: #F3B70A;
  }
 
  .social-media-links h3 {
    margin: 0;
    /* display: block; */
    margin-top: 4rem;
    position: relative;
    top: -3rem;
    right: -8.5rem;
    margin-bottom: 3.5rem;

  }

    .social-media-links {
      display: flex;
       align-items: center;
    }
    
    .social-media-links .icon {
      margin: 0 0.1rem;
      margin-right: 2rem;
      font-size: 2.5rem;
    }
