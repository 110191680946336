.product {
    flex-basis: 30%;
    flex-grow: 1;
    width: 26rem;
    height: 34.5rem;
    background-color: #fff;
    box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
  }

  .product-title{
    width: 100%;
    padding: 0.75rem 1.25rem;
    margin: 0;
    border-bottom: 0.09rem solid rgba(0,0,0,.125);
}

  .product-image{
    height: 20rem;
    width: 100%;
    opacity: 0.9; 
  }

  .product-description{
    width: 100%;
    padding: 0.1rem 1rem; 
    font-size: 0.9rem;
  }

  .button-section{
    background-color: #85a04a; 
    padding: 0.75rem 1.25rem;
    width: 100%;
    position: absolute;
    bottom: 0; 
  }
  

/* mobile size iew */
@media (max-width: 768px) {
  .product {
    width: 22.5rem;
    flex-basis: auto;
  }

  .product-image{
    margin-top: 2rem;
  }

  .product-description{
    font-size: 0.8rem;
  }
}