.products-container {
    margin: 0 2rem;
    margin-bottom: 2rem;
}

.products-title {
    text-align: center;
    font-weight: 700;
    font-size: 2.2rem;
}

.inner-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}


.row {
    display: flex;
    flex-direction: row;
    height: 100%;
    background-color: #f8f8f8;
    border-radius: 10px;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
}

.grid-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.img_inner {
    display: block;
    width: 10rem;
    height: 10rem;
    object-fit: cover;
}

.extra_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    width: 100%;
}

.card-button {
	box-shadow: 0px 10px 15px -7px #657544;
	background:linear-gradient(to bottom, #c0d989 5%, #627a2f 100%);
	background-color:#85a04a;
	border-radius:8px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	/* font-size:1em; */
	font-weight:bold;
	padding:10px 16px;
	text-decoration:none;
	text-shadow:0px 1px 0px #657a39;
}

.card-button:hover {
	background:linear-gradient(to bottom,  #c0d989 5%, #627a2f 100%);
	background-color:#85a04a;
}

.card-button:active {
	position:relative;
	top:1px;
}


.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    border: 1px solid #ccc;
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #fff;
    cursor: pointer;
}

.pagination button.active {
    background-color: #85a04a;
    color: #fff;
}


/* Mobile Size View */
@media only screen and (max-width: 768px) {
    .row {
        flex-direction: row;
        height: 200px;
    }

    .inner-container {
        grid-template-columns: 1fr;
    }
}