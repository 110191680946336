.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff; 
  padding: 0 1.5rem;
  height:4rem;
  width: 100vw;
  top: 0;
}

.logo {
  background-image: url(../../media/vzp-logo.png);
  height: 5rem;
  width: 8rem;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 2.5rem;
}

.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  margin: 0 0.3rem;
}

.menu a {
  text-decoration: none;
  color: #000;
}

.menu li a {
  display: inline-block;
  padding: 0.6rem 1.2rem;
  background-color: #f4f4f4;
  color: #333;
  border-radius: 0.31rem;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

 .menu li a:hover {
  background-color: rgb(15 93 15 ); 
  color: #fff;
  box-shadow: 0.1875rem 0.1875rem 0.375rem rgba(0, 0, 0, 0.3);
}

.menu li a.active {
  background-color: rgb(15 93 15 ); 
  color: #fff;
  border-radius: 0.5rem;
  font-weight: bold;
}

.menu-toggle {
  display: none;
}

/* Mobile Size View */
@media only screen and (max-width: 768px) {

  .navigation{
    position: relative;
    display: block;
    padding: 0;
  }

  .navigation.open{
    height: 18rem;
  }

  .logo {
    margin-top: 0;
    position: relative;
    left: 0.4rem;
    top: 0.9rem;
    height: 3rem;
  }

  .menu {
    flex-direction: column;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .menu li a{
    background-color: #fff;
    box-shadow: none;
    width: 100%;
  }

  .menu.open {
    margin-top: 0.5rem;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }

  .menu li {
    margin: 0.625rem 0.5rem;
  }

  .menu li a.active{
    background-color: #fff;
    color: #000;
  }

 .menu li a:hover {
  background-color: rgba(15, 93, 15, 0.551); 
  color: #000;
  box-shadow: none;
}
    
  .menu-toggle {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 1.25rem;
    right: 0.5rem;
    z-index: 2;
    font-size: 1.8rem;
  }

}
