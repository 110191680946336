.about-page-title {
    text-align: center;
    font-weight: 700;
    font-size: 2.2rem;
}

.about-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    align-items: center;
}

.video {
    grid-column: 1;
}

.about-section p {
    grid-column: 2;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 1rem;
}

.about-page h3 {
    text-align: center;
    margin-bottom: 0.8rem;
    font-size: 1.3rem;
}

.about-page h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.8rem;
}

.box-section {
    margin-top: 1.5rem;
}

.for_box_bg {
    background-color: #f7f7f7;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    padding-bottom: 1rem;
    border: none;
}

.for_box_bg .container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
}

.for_box_bg .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.9rem;
    margin-right: -0.9rem;
}

.section {
    flex: 0 0 25%;
    max-width: 25%;
    padding-right: 0.9rem;
}

.for_box {
    background-color: #ffffff;
    border-radius: 0.25rem;
    padding: 2rem;
    text-align: center;
    margin-top: 1rem;
}

.for_box .icon {
    display: block;
    font-size: 2rem;
    height: auto;
    margin: 0 auto;
    color: #f3b70a;
}

.for_box span {
    display: block;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.for_box h3 {
    font-size: 1.5rem;
    margin: 0;
}

.service-block-inner {
    padding: 0.9rem 1.2rem;
    position: relative;
}

.service-block-inner::before {
    content: "";
    width: 100%;
    height: 0.3 rem;
    background: #000000;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s ease 0s;
}
.service-block-inner::after {
    content: "";
    width: 100%;
    height: 0;
    background: #85a04a;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.5s ease 0s;
}

.service-block-inner:hover::after {
    height: 100%;
    width: 100%;
}

.service-card {
    position: relative;
    width: 100%;
    min-height: 0.1rem;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
}

.service-block-inner h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0.9rem;
    text-transform: uppercase;
}

.service-block-inner p {
    font-size: 0.9rem;
    line-height: 1.5;
    margin: 0;
    font-weight: 300;
    padding-bottom: 0;
}

.service-block-inner:hover h3 {
    color: #ffffff;
    transition: all 0.5s ease 0s;
}

.service-block-inner:hover p {
    color: #ffffff;
    transition: all 0.5s ease 0s;
}
.service-block-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.service-block-container > div {
    flex-basis: 31%;
    margin-bottom: 1.2rem;
}


@media only screen and (max-width: 991.98px) {
    .section {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

/* Mobile Size View */
@media (max-width: 768px) {
    .service-block-container > div {
        flex-basis: 100%;
    }
}

@media (max-width: 575.98px) {
    .section {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
