footer {
    background-color: rgb(53, 94, 59);
    width: 100vw;
    min-height: 32vh;
    margin: 0;
    text-align: center;
    font: 200 0.875rem 'Oswald', sans-serif;
    color: #e2d7d7;
    letter-spacing: 0.1rem;
    position: relative;
    }

    p{
      font-size: 0.8rem;
    }

    footer .summary{
      padding-left: 1rem;
    }
    footer a {
      color: #e2d7d7;
      padding-bottom: 1rem;
    }
    .arrow-icon{
      color: #F3B70A;
      font-size: 1rem;
    }

    .footer-icon{
      font-size: 1.8rem;
      padding-right: 0.6rem;
      padding-top: 0.8rem;
      color: #F3B70A;
    }

    .footer-logo{
      padding-top: 1rem;
      height: 3rem;
      width: 8rem;
      filter: brightness(10%); 
    }

    .footer-row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(15.625rem, 1fr));
      grid-gap: 0.5rem;
    }
    
    .address {
      grid-area: address;
    }
    
    .links_footer {
      grid-area: links;
    }

    footer .copyright{
     font-size: 0.8rem;
     position: absolute;
     bottom: 0;
     left: 50%;
     transform: translateX(-50%);
     text-align: center;
    }

/* Mobile Size View */
@media only screen and (max-width: 768px) {


  footer p{
    font-size: 0.7rem;
  }

  footer .summary{
    padding-left: 0;
  }

  .mobile-font{
    margin: 0;
  }

  .extra-top-margin{
    margin-top: 1rem;
  }

  .links-heading{
    margin-top: 4rem;
  }

  .links_footer {
    display: inline-flex;
    margin: 0;
    padding: 0;
  }


  .footer-icon{
    font-size: 1.3rem;
    padding-right: 0;
  }

  footer a {
    font-size: 0.7rem;
  }

  footer .copyright{
    font-size: 0.7rem;
  }

  .list{
    font-size: 0.7rem;
  }
  
  .footer-list{
    text-align:left;
  }

}

@media only screen 
and (max-width: 1025px) {
  footer {
    min-height: 44vh;
  }

  .footer-row {
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    grid-gap: 1rem;
  }

  footer .mailing-list{
    padding-left: 2rem;
  }
}

@media only screen 
and (max-width: 973px) {

  footer {
    min-height: 44vh;
  }

  .footer-row {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-gap: 1rem;
  }

}

@media only screen 
and (max-width: 767px) {

  footer {
    min-height: 44vh;
  }

  .footer-row {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-gap: 1rem;
  }

}


