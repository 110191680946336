.home {
  max-width: 80rem;
  margin: 0 auto;
  z-index: 9999;
  min-height: 100%;
  padding-bottom: 3rem;
}

.home-title {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 2rem;
  text-align: center;
}

.description {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  text-align: center;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  gap: 2.5rem;
}

/* mobile size iew */
@media (max-width: 768px) {
  .home-title {
    padding: 0 0.2rem;
    margin-top: 0.5rem;
    font-size: 1.6rem;
  }

  .description {
    font-size: 0.9rem;
    padding: 0 0.5rem;
  }

  .home-sub-title{
    text-align: center;
  }

  .products {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

