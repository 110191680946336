.slideshow-container {
    width: 100%;
    position: relative;
    top: 1rem;
  }
  
  .each-slide {
    width: 100rem;
    height: 25rem;

  }
  
  .each-slide > div {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;

  }

  @media (max-width: 768px) {
    .slideshow-container {
      top: 0;
    }
  }
  